
import { computed, defineComponent, inject, onMounted, onUpdated, Ref } from 'vue';

interface ReactiveTabStore {
  value: TabStore;
}
interface TabStore {
  [propName: string]: string;
}

export default defineComponent({
  props: {
    title: {
      required: true,
      type: String,
    },
    tabid: {
      required: true,
      type: String,
    },
    active: Boolean,
  },

  setup(props) {
    const titlecontainer = inject('titlecontainer') as Ref;
    const store = inject('store') as ReactiveTabStore;
    const setActive = inject('setActive') as Function;
    const initialhash = inject('initialhash') as string;

    const scrollHandler: EventListenerOrEventListenerObject = e => {
      const top = document?.getElementById(props.tabid)?.offsetTop;
      window.scrollTo({ top: top, behavior: 'smooth' });
      // @ts-ignore
      e.target.removeEventListener(e.type, arguments.callee);
    };

    if (initialhash == props.tabid) {
      onMounted(() => {
        window.addEventListener('load', scrollHandler);
        // document?.getElementById(props.tabid)?.scrollIntoView();
      });
      setActive(props.tabid);
    }
    if (props.active) setActive(props.tabid);

    onUpdated(() => {
      if (window.location.hash.substr(1) == props.tabid && !store.value[props.tabid]) {
        setActive(props.tabid);
      }
    });

    const isActive = computed(() => store.value[props.tabid] || false);

    return {
      titlecontainer,
      setActive,
      isActive,
    };
  },
});
