, Ref<style lang="scss">
.tab-content {
  opacity: 1;
}
.tab-content-fade-enter-active {
  transition: all 0.5s ease;
}

.tab-content-fade-leave-active {
  position: absolute;
}

.tab-content-fade-enter-from {
  opacity: 0;
}
</style>

<template v-slot:default="slotProps">
  <div :id="tabid" :class="['tab-container', tabid, { active: isActive }]">
    <teleport :to="titlecontainer" :disabled="!titlecontainer">
      <div :id="tabid" :class="['title', tabid, { active: isActive }]" @click="setActive(tabid)">
        <span v-html="title"></span>
      </div>
    </teleport>
    <transition name="tab-content-fade">
      <div class="tab-content" v-if="isActive">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject, onMounted, onUpdated, Ref } from 'vue';

interface ReactiveTabStore {
  value: TabStore;
}
interface TabStore {
  [propName: string]: string;
}

export default defineComponent({
  props: {
    title: {
      required: true,
      type: String,
    },
    tabid: {
      required: true,
      type: String,
    },
    active: Boolean,
  },

  setup(props) {
    const titlecontainer = inject('titlecontainer') as Ref;
    const store = inject('store') as ReactiveTabStore;
    const setActive = inject('setActive') as Function;
    const initialhash = inject('initialhash') as string;

    const scrollHandler: EventListenerOrEventListenerObject = e => {
      const top = document?.getElementById(props.tabid)?.offsetTop;
      window.scrollTo({ top: top, behavior: 'smooth' });
      // @ts-ignore
      e.target.removeEventListener(e.type, arguments.callee);
    };

    if (initialhash == props.tabid) {
      onMounted(() => {
        window.addEventListener('load', scrollHandler);
        // document?.getElementById(props.tabid)?.scrollIntoView();
      });
      setActive(props.tabid);
    }
    if (props.active) setActive(props.tabid);

    onUpdated(() => {
      if (window.location.hash.substr(1) == props.tabid && !store.value[props.tabid]) {
        setActive(props.tabid);
      }
    });

    const isActive = computed(() => store.value[props.tabid] || false);

    return {
      titlecontainer,
      setActive,
      isActive,
    };
  },
});
</script>
