<style lang="scss">
.tab-group-container {
  margin: 50px 0;
  .title-container {
    display: flex;
    //border-bottom: 1px solid #eee;
    justify-content: center;
    box-shadow: 0 4px 6px -6px rgba(68, 68, 68, 0.6);
    @media (max-width: 992px) {
      display: block !important;
      text-align: center;
    }
    .title {
      cursor: pointer;
      padding: 15px 20px;
      transition: all 0.5s ease;
      //border-bottom: 3px solid transparent;
      i {
        margin-right: 5px;
      }

      &:hover {
        background: #eee;
      }
    }
    .active {
      //border-bottom: 3px solid #ccc;
      background: $blue-darker;
      color: #fff;
      &:hover {
        //background: transparent;
        &:hover {
          background: $blue-darker;
        }
        color: #fff;
      }
    }
  }

  .tab-content {
    margin-top: 0px;
    background: transparent;
    padding: 20px 0;
  }
}
</style>
<template>
  <div class="tab-group-container">
    <div class="title-container" ref="titlecontainer"></div>
    <div class="tab-content">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, provide, ref } from 'vue';

interface TabStore {
  [propName: string]: boolean;
}

export default defineComponent({
  setup() {
    const titlecontainer = ref(null);
    const store = ref<TabStore>({});
    const hash = window.location.hash.substring(1);

    provide('titlecontainer', titlecontainer);
    provide('setActive', (id: string) => {
      Object.keys(store.value).map(tab => {
        store.value[tab] = false;
      });
      store.value[id] = true;
      window.location.hash = id;
    });
    provide('store', store);
    provide('initialhash', hash);

    return {
      titlecontainer,
    };
  },
});
</script>
